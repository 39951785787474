import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";

import SEO from "../../components/seo";

const posts = [
  {
    title: "Corporate sponsorship",
    href: "#",

    description: "Support our community projects through cash sponsorships",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
    imageUrl:
      "https://res.cloudinary.com/dkm14ge3g/image/upload/v1655437578/participatePage/nsdq0pl2pw6keug3nfux.webp",
    link: "/participate/corporate",
  },

  {
    title: "Dinner table sponsorship",
    href: "#",

    description: "Help us by booking a dinner table for your guests",
    date: "Mar 10, 2020",
    datetime: "2020-03-10",
    imageUrl:
      "https://res.cloudinary.com/dkm14ge3g/image/upload/v1655437854/participatePage/myzc5ohiybqiu2lqamlo.webp",
    link: "/participate/table",
  },
  {
    title: "Programme e-booklet advertisement",
    href: "#",

    description: "Advertise on our Charity Gala Dinner programme e-booklet",
    date: "Feb 12, 2020",
    datetime: "2020-02-12",
    imageUrl:
      "https://res.cloudinary.com/dkm14ge3g/image/upload/v1685849818/participatePage/vmeelydffm3zwf8jwmea.jpg",
    link: "/participate/advertisement",
  },
];

const Sponsor = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Sponsorship opportunities"
          description="SWA welcome sponsorships
                     and financial support from individuals and corporate
                     member volunteers for our charity efforts."
        />
        <section className="max-w-5xl px-2 pt-1 mx-auto sm:w-4/5">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3" />
          </div>
          <div className="relative mx-auto max-w-7xl">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold tracking-tight text-swa-1 sm:text-4xl">
                Be a sponsor
              </h2>
              
              <h2 className="mt-10 mb-5 text-xl font-bold text-swa-1 sm:text-3xl sm:tracking-tight lg:text-5xl">
            47th SWA Annual Charity Gala 2024
          </h2>
          <div className="inline-block mt-5 rounded-md shadow">
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/swa-admin-23d1e.appspot.com/o/download%2F47th%20Charity%20Dinner%20e-booklet-compressed.pdf?alt=media&token=8d60ab25-ec85-42db-9dcf-c8bfa792ce71&utm_campaign=charitydinner&utm_source=sponsorpage"
                  className="flex items-center justify-center px-3 py-3 mt-3 text-lg font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                  target="_blank"
                >
                  Download Souvenir Programme Booklet (PDF 6MB)
                </a>
              </div>
              <p className="max-w-2xl mx-auto mt-3 text-lg text-gray-500 sm:mt-4">
                We're excited to celebrate the 70th anniversary of the Singapore
                Women's Association. Inside, you'll find details on the event
                schedule, photos of the finalists for the Miss Singapore Pageant
                International 2024, and information about prizes for Guess The
                Winner. The booklet also provides an overview of the Singapore
                Women's Association 70 years history. We hope you enjoy this
                special evening filled with pageantry, philanthropy, and
                community. Thank you for your support.
              </p>


              <img
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1720663662/participatePage/kdxua2eefnih4lbk2nug.webp"
                alt="SWA Charity Gala"
                width={1000}
                height={700}
                className="mx-auto mt-5 sm:max-w-xl"
              />
     
          <div className="inline-block mt-5 rounded-md shadow">
   
            <a
              href="https://www.canva.com/design/DAF3IGBVKoE/uG0OrofrBOHQq1yn_0B47Q/edit?utm_content=DAF3IGBVKoE&utm_campaign=designshare&utm_medium=link2&utm_source=sharebuttong"
              className="flex items-center justify-center px-5 py-3 mb-5 text-2xl font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
              target="_blank"
            >
              See sponsorship opportunities
            </a>
          </div>
            </div>
           
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Sponsor;

{
  /* <div className="relative ">
           <div
             className="absolute inset-x-0 top-0 hidden h-1/2 bg-gray-50 lg:block"
             aria-hidden="true"
           />
           <div className="mx-auto bg-swa-3 max-w-7xl lg:bg-transparent ">
             <div className="lg:grid lg:grid-cols-12">
               <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
                 <div
                   className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"
                   aria-hidden="true"
                 />
                 <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                   <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                     <img
                       className="object-cover object-center shadow-2xl rounded-3xl"
                       src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636989152/participatePage/mhjohug8ynomciowkewi.jpg"
                       alt="Sponsorship"
                     />
                   </div>
                 </div>
               </div>
 
               <div className="relative bg-swa-1 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
                 <div
                   className="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block"
                   aria-hidden="true"
                 >
                   <svg
                     className="absolute transform bottom-full left-full translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                     width={404}
                     height={384}
                     fill="none"
                     viewBox="0 0 404 384"
                     aria-hidden="true"
                   >
                     <defs>
                       <pattern
                         id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                         x={0}
                         y={0}
                         width={20}
                         height={20}
                         patternUnits="userSpaceOnUse"
                       >
                         <rect
                           x={0}
                           y={0}
                           width={4}
                           height={4}
                           className="text-gray-500 "
                           fill="currentColor"
                         />
                       </pattern>
                     </defs>
                     <rect
                       width={404}
                       height={384}
                       fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                     />
                   </svg>
                   <svg
                     className="absolute transform top-full -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
                     width={404}
                     height={384}
                     fill="none"
                     viewBox="0 0 404 384"
                     aria-hidden="true"
                   >
                     <defs>
                       <pattern
                         id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                         x={0}
                         y={0}
                         width={20}
                         height={20}
                         patternUnits="userSpaceOnUse"
                       >
                         <rect
                           x={0}
                           y={0}
                           width={4}
                           height={4}
                           className="text-indigo-500"
                           fill="currentColor"
                         />
                       </pattern>
                     </defs>
                     <rect
                       width={404}
                       height={384}
                       fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                     />
                   </svg>
                 </div>
                 <div className="relative max-w-md px-4 py-12 mx-auto space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-5 lg:col-start-4 lg:col-span-6">
                   <h2
                     className="text-3xl font-extrabold text-white"
                     id="join-heading"
                   >
                     Be a sponsor
                     <span className="block font-light ">
                       Enable us to continue to do good for the underprivileged
                     </span>
                   </h2>
 
                   <p className="text-xl leading-8 prose prose-lg text-white">
                     You can be one of our sponsors. SWA welcome sponsorships
                     and financial support from individuals and corporate
                     member volunteers for our charity efforts. Sponsorship
                     contributions will enable us to continue to achieve our
                     goal and provide more programmes to our beneficiaries.
                   </p>
                   <Link
                     className="block w-full px-5 py-3 text-base font-medium text-center bg-white border border-transparent rounded-md shadow-md text-swa-2 hover:bg-gray-50 sm:inline-block sm:w-auto"
                     to="/forms/contact"
                   >
                     Contact us
                   </Link>
 
                 </div>
               </div>
             </div>
           </div>
         </div> */
}
